<template>
  <Menubar :model="items" class="dark">
    <template #start>
      <img
        alt="logo"
        @click="$router.push('/admin/')"
        src="../../assets/logo.png"
        height="40"
        class="p-mr-2"
      />
    </template>
    <template #end>
      <i
        class="pi pi-flag p-text-secondary"
        style="font-size: 2rem;margin-left: 20px;"
        v-badge="countCirculars"
        @click="toggleCirculars"
        aria:haspopup="true"
        aria-controls="overlay_panel_countCirculars"
      ></i>

      <i
        class="pi pi-bell p-text-secondary"
        style="font-size: 2rem;margin-left: 8px;"
        v-badge="countNotifications"
        @click="toggleNotfy"
        aria:haspopup="true"
        aria-controls="overlay_panel"
      ></i>

      <OverlayPanel
        ref="op"
        appendTo="body"
        :showCloseIcon="true"
        id="overlay_panel"
        style="width: 450px"
      >
        <DataTable :value="notifications" :paginator="true" :rows="5">
          <Column>
            <template #body="slotProps">
              <p
                @click="
                  openNotification(slotProps.data.id, slotProps.data.tableName)
                "
              >
                {{ slotProps.data.name }} #{{ slotProps.data.code }} #{{
                  slotProps.data.clientName
                }}
              </p>
            </template>
          </Column>
        </DataTable>
      </OverlayPanel>

      <Button
        :label="userName"
        @click="toggle"
        aria-haspopup="true"
        aria-controls="overlay_tmenu"
        class="p-button-default  "
      />
      <TieredMenu
        id="overlay_tmenu"
        ref="menu"
        :model="itemsList"
        :popup="true"
      />
    </template>
  </Menubar>
</template>

<script>
import Menubar from 'primevue/menubar';
export default {
  components: {
    Menubar,
  },
  name: 'app-header',
  data() {
    const user = JSON.parse(localStorage.admin);
    const xcount = this.countWords(user.name);
    let userName = user.name;
    if (xcount > 1) {
      userName = userName.split(' ')[0];
    }
    return {
      userName,
      items: [
        {
          label: 'الرئيسيه',
          icon: 'pi pi-fw pi-home',
          to: '/admin',
        },
      ],
      itemsList: [
        {
          label: 'الملف الشخصي',
          icon: 'pi pi-fw pi-user',
          to: '/admin/profile',
        },
        {
          separator: true,
        },
        {
          label: 'تسجيل الخروج',
          icon: 'pi pi-fw pi-power-off',
          command: () => {
            this.logout();
          },
        },
      ],
      list: [],
      notifications: [],
      user,
      countNotifications: 0,
      countCirculars: 0,
      circulars: [],
    };
  },
  methods: {
    countWords(str) {
      return str.trim().split(/\s+/).length;
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    openNotification(id, tableName) {
      this.$http.get(`notifications/updateOpenByUser/${id}`).then((res) => {
        this.$http
          .get(`notifications/getByUser/` + this.user.id)
          .then((response) => {
            this.notifications = response.data;
            this.countNotifications = this.notifications.filter(
              (el) => el.show == false,
            ).length;
          });
      });
      if (tableName == 'contracts') {
        this.$router.push('/admin/sales/contracts');
      }
      if (tableName == 'collection') {
        this.$router.push('/admin/sales/collection');
      }

      if (tableName == 'tasks') {
        this.$router.push('/admin/sales/tasks');
      }
      if (tableName == 'installations') {
        this.$router.push('/admin/technicalSupport/installations');
      }

      if (tableName == 'distributions') {
        this.$router.push('/admin/technicalSupport/distributions');
      }

      if (tableName == 'operations') {
        this.$router.push('/admin/technicalSupport/operations');
      }

      if (tableName == 'revisions') {
        this.$router.push('/admin/technicalSupport/revisions');
      }

      if (tableName == 'delivery_notes') {
        this.$router.push('/admin/technicalSupport/deliveryNotes');
      }

      if (tableName == 'delivery') {
        this.$router.push('/admin/technicalSupport/delivery');
      }

      if (tableName == 'welcome') {
        this.$router.push('/admin/secretary/welcome');
      }
    },
    toggleNotfy(event) {
      this.$http
        .get(`notifications/updateShowByUser/${this.user.id}`)
        .then((response) => {
          this.countNotifications = 0;
        });

      this.$refs.op.toggle(event);
    },
    toggleCirculars(event) {
      this.$http
        .get(`circulars/updateShowByUser/${this.user.id}`)
        .then((response) => {
          this.countCirculars = 0;
        });

      this.$router.push('/admin/circulars');
    },
    logout() {
      delete localStorage.admin;
      location.reload();
    },
  },
  created() {
    this.$http
      .get(`notifications/getByUser/` + this.user.id)
      .then((response) => {
        this.notifications = response.data;
        this.countNotifications = this.notifications.filter(
          (el) => el.show == false,
        ).length;
      });

    this.$http.get(`circulars/getByUser/` + this.user.id).then((response) => {
      this.circulars = response.data;
      this.countCirculars = this.circulars.filter(
        (el) => el.show == false,
      ).length;
    });

    this.$http.get(`installations/getAllDistributions`).then((response) => {
      this.list = response.data;
    });

    this.items = [];

    if (this.$checkRoles('systemMenuVeiw')) {
      const systemMenu = [];

      if (this.$checkRoles('companyVeiw')) {
        systemMenu.push({
          label: 'اعدادت الشركه',
          icon: 'pi pi-fw pi-shield',
          to: '/admin/company',
        });
      }

      if (this.$checkRoles('notificationsVeiw')) {
        systemMenu.push({
          label: 'اعدادت الاشعارات',
          icon: 'pi pi-fw pi-bell',
          to: '/admin/notifications',
        });
      }

      if (this.$checkRoles('branchesVeiw')) {
        systemMenu.push({
          label: 'فروع الشركه',
          icon: 'pi pi-fw pi-sitemap',
          to: '/admin/system/branches',
        });
      }

      if (this.$checkRoles('countriesVeiw')) {
        systemMenu.push({
          label: 'الدول',
          icon: 'pi pi-fw pi-compass',
          to: '/admin/system/countries',
        });
      }
      if (this.$checkRoles('citiesVeiw')) {
        systemMenu.push({
          label: 'المدن',
          icon: 'pi pi-fw pi-directions',
          to: '/admin/system/cities',
        });
      }
      if (this.$checkRoles('currenciesVeiw')) {
        systemMenu.push({
          label: 'العملات',
          icon: 'pi pi-fw pi-money-bill',
          to: '/admin/system/currencies',
        });
      }
      if (this.$checkRoles('productsVeiw')) {
        systemMenu.push({
          label: 'المنتجات والخدمات',
          icon: 'pi pi-fw pi-list',
          to: '/admin/system/products',
        });
      }
      if (this.$checkRoles('surveysVeiw')) {
        systemMenu.push({
          label: 'بنود تقييم النظام',
          icon: 'pi pi-fw pi-check-circle',
          to: '/admin/system/surveys',
        });
      }

      if (this.$checkRoles('taxesVeiw')) {
        systemMenu.push({
          label: 'الضرائب',
          icon: 'pi pi-fw pi-money-bill',
          to: '/admin/system/taxes',
        });
      }

      if (this.$checkRoles('warehouseVeiw')) {
        systemMenu.push({
          label: 'المستودعات',
          icon: 'pi pi-fw pi-eject',
          to: '/admin/system/warehouse',
        });
      }

      this.items.push({
        label: 'النظام',
        icon: 'pi pi-fw pi-th-large',
        items: systemMenu,
      });
    }

    if (this.$checkRoles('usersMenuVeiw')) {
      const usersMenu = [];

      if (this.$checkRoles('rolesVeiw')) {
        usersMenu.push({
          label: 'صلاحيات المستخدم',
          icon: 'pi pi-fw pi-key',
          to: '/admin/users/roles',
        });
      }
      if (this.$checkRoles('adminsVeiw')) {
        usersMenu.push({
          label: 'مستخدمين النظام',
          icon: 'pi pi-fw pi-user',
          to: '/admin/users/admins',
        });
      }
      if (this.$checkRoles('clientsVeiw')) {
        usersMenu.push({
          label: 'عملاء الشركه',
          icon: 'pi pi-fw pi-id-card',
          to: '/admin/users/clients',
        });
      }

      if (this.$checkRoles('administrationVeiw')) {
        usersMenu.push({
          label: 'الادارات',
          icon: 'pi pi-fw pi-clone',
          to: '/admin/system/administration',
        });
      }
      if (this.$checkRoles('sectionsVeiw')) {
        usersMenu.push({
          label: 'الاقسام',
          icon: 'pi pi-fw pi-tags',
          to: '/admin/system/sections',
        });
      }
      if (this.$checkRoles('employeesVeiw')) {
        usersMenu.push({
          label: 'الوظائف',
          icon: 'pi pi-fw pi-tag',
          to: '/admin/system/employees',
        });
      }
      if (this.$checkRoles('clientGroupsVeiw')) {
        usersMenu.push({
          label: 'مجموعات العملاء',
          icon: 'pi pi-fw pi-ticket',
          to: '/admin/system/clientGroups',
        });
      }

      if (this.$checkRoles('logsVeiw')) {
        usersMenu.push({
          label: 'السجل',
          icon: 'pi pi-fw pi-ticket',
          to: '/admin/logs',
        });
      }

      this.items.push({
        label: 'المستخدمين',
        icon: 'pi pi-fw pi-users',
        items: usersMenu,
      });
    }

    if (this.$checkRoles('salesMenuVeiw')) {
      const salesMenu = [];

      if (this.$checkRoles('contractsVeiw')) {
        salesMenu.push({
          label: 'العقود',
          icon: 'pi pi-fw pi-book',
          to: '/admin/sales/contracts',
        });
      }

      if (this.$checkRoles('collectionVeiw')) {
        salesMenu.push({
          label: 'الحسابات',
          icon: 'pi pi-fw pi-folder-open',
          to: '/admin/sales/collection',
        });
      }
      if (this.$checkRoles('materialsVeiw')) {
        salesMenu.push({
          label: 'المخازن',
          icon: 'pi pi-fw pi-money-bill',
          to: '/admin/sales/materials',
        });
      }

      if (this.$checkRoles('tasksVeiw')) {
        salesMenu.push({
          label: 'متابعة العملاء المستهدفين',
          icon: 'pi pi-fw pi-list',
          to: '/admin/sales/tasks',
        });
      }

      if (this.$checkRoles('purchasesVeiw')) {
        salesMenu.push({
          label: 'اوامر الشراء',
          icon: 'pi pi-fw pi-check-square',
          to: '/admin/sales/purchases',
        });
      }

      if (this.$checkRoles('tasksOrderVeiw')) {
        salesMenu.push({
          label: 'مهام عمل',
          icon: 'pi pi-fw pi-flag',
          to: '/admin/sales/tasksOrder',
        });
      }

      if (this.$checkRoles('tasksOrderDoneVeiw')) {
        salesMenu.push({
          label: 'تنفيذ مهام عمل',
          icon: 'pi pi-fw pi-map',
          to: '/admin/sales/tasksOrderDone',
        });
      }

      this.items.push({
        label: 'المبيعات',
        icon: 'pi pi-fw pi-chart-line',
        items: salesMenu,
      });
    }

    if (this.$checkRoles('inventoryMenuVeiw')) {
      const inventoryMenu = [];

      if (this.$checkRoles('itemsGroupsVeiw')) {
        inventoryMenu.push({
          label: 'مجموعه الاصناف',
          icon: 'pi pi-fw pi-list',
          to: '/admin/inventory/itemsGroups',
        });
      }

      if (this.$checkRoles('itemsTypesVeiw')) {
        inventoryMenu.push({
          label: 'انواع الاصناف',
          icon: 'pi pi-fw pi-table',
          to: '/admin/inventory/itemsTypes',
        });
      }
      if (this.$checkRoles('itemsUnitsVeiw')) {
        inventoryMenu.push({
          label: 'الوحدات',
          icon: 'pi pi-fw pi-clone',
          to: '/admin/inventory/itemsUnits',
        });
      }
      if (this.$checkRoles('storeItemsVeiw')) {
        inventoryMenu.push({
          label: 'الاصناف المخزنيه',
          icon: 'pi pi-fw pi-file',
          to: '/admin/inventory/storeItems',
        });
      }

      inventoryMenu.push({
        separator: true,
      });
      if (this.$checkRoles('openingBalanceVeiw')) {
        inventoryMenu.push({
          label: 'الرصيد الافتتاحي',
          icon: 'pi pi-fw pi-arrow-circle-right',
          to: '/admin/inventory/openingBalance',
        });
      }
      if (this.$checkRoles('receivingInventoryVeiw')) {
        inventoryMenu.push({
          label: 'الاستلام المخزني',
          icon: 'pi pi-fw pi-plus-circle',
          to: '/admin/inventory/receivingInventory',
        });
      }
      if (this.$checkRoles('inventoryStoreCountingReqVeiw')) {
        inventoryMenu.push({
          label: 'طلب صرف المخزني',
          icon: 'pi pi-fw pi-minus-circle',
          to: '/admin/inventory/inventoryStoreCountingReq',
        });
      }
      if (this.$checkRoles('inventoryStoreCountingVeiw')) {
        inventoryMenu.push({
          label: 'الصرف المخزني',
          icon: 'pi pi-fw pi-minus-circle',
          to: '/admin/inventory/inventoryStoreCounting',
        });
      }
      if (this.$checkRoles('inventoryAdjustmentVeiw')) {
        inventoryMenu.push({
          label: 'الجرد المخزني',
          icon: 'pi pi-fw pi-sort-alt',
          to: '/admin/inventory/inventoryAdjustment',
        });
      }
      if (this.$checkRoles('inventoryDamageStoreItemsVeiw')) {
        inventoryMenu.push({
          label: 'الاتلاف المخزني',
          icon: 'pi pi-fw pi-times-circle',
          to: '/admin/inventory/inventoryDamageStoreItems',
        });
      }
      if (this.$checkRoles('inventoryDirectTransferVeiw')) {
        inventoryMenu.push({
          label: 'التحويل المخزني',
          icon: 'pi pi-fw pi-sort-alt',
          to: '/admin/inventory/inventoryDirectTransfer',
        });
      }

      this.items.push({
        label: 'المخازن',
        icon: 'pi pi-fw pi-table',
        items: inventoryMenu,
      });
    }

    if (this.$checkRoles('technicalSupportMenuVeiw')) {
      const technicalSupportMenu = [];

      // roles
      if (this.$checkRoles('distributionsVeiw')) {
        technicalSupportMenu.push({
          label: 'توزيع اوامر التركيب',
          icon: 'pi pi-fw pi-check-square',
          to: '/admin/technicalSupport/distributions',
        });
      }

      if (this.$checkRoles('operationsVeiw')) {
        technicalSupportMenu.push({
          label: 'اوامر التشغيل',
          icon: 'pi pi-fw pi-check-circle',
          to: '/admin/technicalSupport/operations',
        });
      }

      if (this.$checkRoles('revisionsVeiw')) {
        technicalSupportMenu.push({
          label: 'مراجعة اوامر التشغيل',
          icon: 'pi pi-fw pi-step-forward',
          to: '/admin/technicalSupport/revisions',
        });
      }
      if (this.$checkRoles('installationsVeiw')) {
        technicalSupportMenu.push({
          label: 'اوامر التركيب',
          icon: 'pi pi-fw pi-cloud-download',
          to: '/admin/technicalSupport/installations',
        });
      }
      if (this.$checkRoles('deliveryNotesVeiw')) {
        technicalSupportMenu.push({
          label: 'ملاحظات تسليم العملاء',
          icon: 'pi pi-fw pi-step-backward',
          to: '/admin/technicalSupport/deliveryNotes',
        });
      }

      this.items.push({
        label: 'التركيبات',
        icon: 'pi pi-fw pi-briefcase',
        class: 'technicalSupport',
        items: technicalSupportMenu,
      });
    }

    if (this.$checkRoles('secretaryMenuVeiw')) {
      const secretaryMenu = [];
      if (this.$checkRoles('deliveryVeiw')) {
        secretaryMenu.push({
          label: 'تسليم العملاء',
          icon: 'pi pi-fw pi-cloud',
          to: '/admin/technicalSupport/delivery',
        });
      }

      if (this.$checkRoles('welcomeVeiw')) {
        secretaryMenu.push({
          label: 'اغلاق العقود',
          icon: 'pi pi-fw pi-phone',
          to: '/admin/secretary/welcome',
        });
      }

      // if (this.$checkRoles('secretarySurveysVeiw')) {
      //   secretaryMenu.push({
      //     label: 'تقيم العملاء للعقود',
      //     icon: 'pi pi-fw pi-filter',
      //     to: '/admin/secretary/surveys',
      //   });
      // }

      if (this.$checkRoles('circularsVeiw')) {
        secretaryMenu.push({
          label: 'التعاميم',
          icon: 'pi pi-fw pi-key',
          to: '/admin/secretary/circulars',
        });
      }

      this.items.push({
        label: 'السكرتاريه',
        icon: 'pi pi-fw pi-send',
        items: secretaryMenu,
      });
    }

    if (this.$checkRoles('reportsMenuVeiw')) {
      const reportsMenu = [];
      const reportsSalesMenu = [];

      if (this.$checkRoles('reportsTotalSalesVeiw')) {
        reportsSalesMenu.push({
          label: 'اجمالى المبيعات',
          icon: 'pi pi-fw pi-chart-line',
          to: '/admin/reports/sales/totalSales',
        });
      }

      if (this.$checkRoles('reportsSalesClientsVeiw')) {
        reportsSalesMenu.push({
          label: 'تقرير العملاء',
          icon: 'pi pi-fw pi-users',
          to: '/admin/reports/sales/clients',
        });
      }

      if (this.$checkRoles('reportsSalesContractsVeiw')) {
        reportsSalesMenu.push({
          label: 'تقرير العقود',
          icon: 'pi pi-fw pi-link',
          to: '/admin/reports/sales/contracts',
        });
      }

      if (this.$checkRoles('reportsSalesContractsBayVeiw')) {
        reportsSalesMenu.push({
          label: 'سداد العقود',
          icon: 'pi pi-fw pi-money-bill',
          to: '/admin/reports/sales/contractsBay',
        });
      }

      if (this.$checkRoles('reportsSalesMaterialsVeiw')) {
        reportsSalesMenu.push({
          label: 'المخازن',
          icon: 'pi pi-fw pi-clone',
          to: '/admin/reports/sales/materials',
        });
      }

      if (this.$checkRoles('reportsSalesCollectionVeiw')) {
        reportsSalesMenu.push({
          label: 'تقرير التحصيل',
          icon: 'pi pi-fw pi-percentage',
          to: '/admin/reports/sales/collection',
        });
      }
      if (this.$checkRoles('reportsSalesTasksVeiw')) {
        reportsSalesMenu.push({
          label: 'تقرير متابعة العملاء المستهدفين',
          icon: 'pi pi-fw pi-list',
          to: '/admin/reports/sales/tasks',
        });
      }

      if (this.$checkRoles('reportsSalesMaterialsVeiw')) {
        reportsSalesMenu.push({
          label: 'تقرير اوامر الشراء',
          icon: 'pi pi-fw pi-clock',
          to: '/admin/reports/sales/purchases',
        });
      }

      if (this.$checkRoles('reportsSalesTasksOrderDoneVeiw')) {
        reportsSalesMenu.push({
          label: 'تقرير مهام العمل',
          icon: 'pi pi-fw pi-clock',
          to: '/admin/reports/sales/tasksOrderDone',
        });
      }

      // inventory
      const inventoryMenuReport = [];

      if (this.$checkRoles('reportsInventoryStoreItemsVeiw')) {
        inventoryMenuReport.push({
          label: 'كارت الصنف اجمالي',
          icon: 'pi pi-fw pi-list',
          to: '/admin/reports/inventoryStoreItems',
        });
      }
      if (this.$checkRoles('reportsinventoryStoreItemsInfoWVeiw')) {
        inventoryMenuReport.push({
          label: 'اجمالى كارت الصنف بالمستودع',
          icon: 'pi pi-fw pi-file',
          to: '/admin/reports/inventoryStoreItemsInfoW',
        });
      }
      if (this.$checkRoles('reportsInventoryStoreItemsInfo')) {
        inventoryMenuReport.push({
          label: 'كارت الصنف تفصيلى',
          icon: 'pi pi-fw pi-file',
          to: '/admin/reports/inventoryStoreItemsInfo',
        });
      }

      if (this.$checkRoles('reportsInventoryStoreItemsInfoTwo')) {
        inventoryMenuReport.push({
          label: 'كارت الصنف مفصل',
          icon: 'pi pi-fw pi-file',
          to: '/admin/reports/inventoryStoreItemsInfoTwo',
        });
      }

      if (this.$checkRoles('reportsinventoryBalanceVeiw')) {
        inventoryMenuReport.push({
          label: 'المعاملات',
          icon: 'pi pi-fw pi-sort-alt',
          to: '/admin/reports/inventoryBalance',
        });
      }

      const inventoryTwoMenuReport=[];

      if (this.$checkRoles('reportsopeningBalanceVeiw')) {
        inventoryTwoMenuReport.push({
          label: 'الأرصده الافتتاحيه للمخازن',
          icon: 'pi pi-fw pi-arrow-circle-right',
          to: '/admin/reports/openingBalance',
        });
      }
      if (this.$checkRoles('reportsinventoryDamageStoreItemsVeiw')) {
        inventoryTwoMenuReport.push({
          label: 'الأتلاف المخزني - تفصيلي',
          icon: 'pi pi-fw pi-times-circle',
          to: '/admin/reports/inventoryDamageStoreItems',
        });
      }

      if (this.$checkRoles('reportsreceivingInventoryVeiw')) {
        inventoryTwoMenuReport.push({
          label: 'الاستلام المخزني - تفصيلي',
          icon: 'pi pi-fw pi-plus-circle',
          to: '/admin/reports/receivingInventory',
        });
      }

      if (this.$checkRoles('reportsinventory_store_counting_reqVeiw')) {
        inventoryTwoMenuReport.push({
          label: 'طلب الصرف المخزني - تفصيلي',
          icon: 'pi pi-fw pi-minus-circle',
          to: '/admin/reports/inventory_store_counting_req',
        });
      }
      if (this.$checkRoles('reportsinventoryStoreCountingVeiw')) {
        inventoryTwoMenuReport.push({
          label: 'الصرف المخزني - تفصيلي',
          icon: 'pi pi-fw pi-minus-circle',
          to: '/admin/reports/inventoryStoreCounting',
        });
      }

      if (this.$checkRoles('reportsinventoryStoreCountingTwoVeiw')) {
        inventoryTwoMenuReport.push({
          label: 'الصرف المخزني - مفصل',
          icon: 'pi pi-fw pi-minus-circle',
          to: '/admin/reports/inventoryStoreCountingTwo',
        });
      }

      if (this.$checkRoles('reportsinventoryAdjustmentVeiw')) {
        inventoryTwoMenuReport.push({
          label: 'الجرد المخزني - تفصيلي',
          icon: 'pi pi-fw pi-sort-alt',
          to: '/admin/reports/inventoryAdjustment',
        });
      }

      inventoryMenuReport.unshift({
        label: 'المعاملات مفصله',
        icon: 'pi pi-fw pi-table',
        items: inventoryTwoMenuReport,
      });
      reportsMenu.push({
        label: 'المخازن',
        icon: 'pi pi-fw pi-table',
        items: inventoryMenuReport,
      });

      reportsMenu.push({
        label: 'المبيعات',
        icon: 'pi pi-fw pi-chart-line',
        items: reportsSalesMenu,
      });

      if (this.$checkRoles('reportsSalesContractsDeliveryDateVeiw')) {
        reportsMenu.push({
          label: 'انحراف تنفيذ العقود',
          icon: 'pi pi-fw pi-filter',
          to: '/admin/reports/sales/contractsDeliveryDate',
        });
      }

      // if (this.$checkRoles('reportsSalesDistributionsVeiw')) {
      //   reportsMenu.push({
      //     label: 'اوامر التركيب تحت التوزيع',
      //     icon: 'pi pi-fw pi-check-square',
      //     to: '/admin/reports/sales/distributions',
      //   });
      // }

      if (this.$checkRoles('reportsSalesInstallationsVeiw')) {
        reportsMenu.push({
          label: 'تقرير التركيبات',
          icon: 'pi pi-fw pi-eject',
          to: '/admin/reports/sales/installations',
        });
      }

      // if (this.$checkRoles('reportsSalesOperationsVeiw')) {
      //   reportsMenu.push({
      //     label: 'تقرير اوامر التشغيل',
      //     icon: 'pi pi-fw pi-check-circle',
      //     to: '/admin/reports/sales/operations',
      //   });
      // }

      // if (this.$checkRoles('reportsSalesRevisionsVeiw')) {
      //   reportsMenu.push({
      //     label: 'اوامر التشغيل تحت المراجعة',
      //     icon: 'pi pi-fw pi-step-forward',
      //     to: '/admin/reports/sales/revisions',
      //   });
      // }

      // if (this.$checkRoles('reportsSalesDeliveryVeiw')) {
      //   reportsMenu.push({
      //     label: 'اوامر التركيب للاستلام النهائي',
      //     icon: 'pi pi-fw pi-cloud',
      //     to: '/admin/reports/sales/delivery',
      //   });
      // }

      // if (this.$checkRoles('reportsSalesDeliveryNotesVeiw')) {
      //   reportsMenu.push({
      //     label: 'تقرير ملاحظات استلام العملاء',
      //     icon: 'pi pi-fw pi-cloud',
      //     to: '/admin/reports/sales/deliveryNotes',
      //   });
      // }

      // if (this.$checkRoles('reportsSalesWelcomeVeiw')) {
      //   reportsMenu.push({
      //     label: 'اغلاق العقود',
      //     icon: 'pi pi-fw pi-phone',
      //     to: '/admin/reports/sales/welcome',
      //   });
      // }

      if (this.$checkRoles('reportsSalesNotificationsVeiw')) {
        reportsMenu.push({
          label: 'الاشعارات',
          icon: 'pi pi-fw pi-bell',
          to: '/admin/reports/sales/notifications',
        });
      }

      if (this.$checkRoles('reportsSurveysVeiw')) {
        reportsMenu.push({
          label: 'تقرير تقيم العملاء',
          icon: 'pi pi-fw pi-inbox',
          to: '/admin/reports/sales/surveys',
        });
      }

      if (this.$checkRoles('reportsSurveysCircularsVeiw')) {
        reportsMenu.push({
          label: 'تقرير التعميم',
          icon: 'pi pi-fw pi-key',
          to: '/admin/reports/sales/circulars',
        });
      }

      this.items.push({
        label: 'التقرير',
        icon: 'pi pi-fw pi-print',
        items: reportsMenu,
      });
    }
  },
};
</script>
