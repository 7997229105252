<template>
  <div class="layout-footer">
    <span class="footer-text" style="margin-right: 5px;">Copyright</span>
    <!-- <img
     
      src="assets/layout/images/logo.svg"
      alt="sigma"
      width="80"
    /> -->
    <a href="#">
      <span class="footer-text" style="margin-left: 5px;"
        >© 2021 
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'app-footer',
  props: {
    list: [],
  },
};
</script>

<style>
.layout-footer {
  background-color: #fff;
  padding: 1em 2em;
  color: #333;

  margin-top: auto;
}

.layout-footer a {
  color: #333;
  text-decoration: none;
}
</style>
